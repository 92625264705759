export const requirementsServices = {
  postRequirements,
  payment,
};

const hostname = process.env.NEXT_PUBLIC_API_HOST;

const stanzaPlaces = [
  "Koramangala",
  "HSR Layout",
  "HSR layout Sector 2, 1st Stage, BTM Layout",
  "HSR Layout 5th Sector",
  "Electronic City",
  "Electronic City Phase II, Electronic City",
  "Electronics City Phase 1, Electronic City",
  "Aundh",
  "Baner",
  "Wakad",
  "Kharghar Golf Course, Kharghar",
  "Hinjewadi",
  "Powai",
  "Chembur",
  "Bandra",
  "Gachibowli",
  "Madhapur",
  "Kondapur",
  "Sohna",
  "HUDA Complex",
  "DLF Golf Course, Sector 42",
  "Mahabalipuram",
  "Nungambakkam",

  //22nd June 2022
  "Fursungi",
  "Bellandur",
  "Hinjawadi",
  "Karapakkam",
  "Kukatpally",
  "Manayata Tech Park, Nagavara",
  "Varthur",
  "Whitefield",
  "Perungudi",
  "Nagavara",
  "Navalur",
  "Marathahalli",
  "Kundalahalli, Brookefield",
  "Sholinganallur",
  "BTM Layout",
  "BTM 1st Stage",
  "BTM 2nd Stage",
  "Kothrud",

  "Electronics City Phase II, Electronic City",
  "Phase 2, Electronic City",
  "Electronics City Phase 1, Electronic City",
  "Electronics City",
];

function postRequirements(requirementsDetails) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(requirementsDetails),
  };
  
  return fetch(`${hostname}/api/v1/requirements/`, requestOptions).then(
    handleResponse
  );
}

function payment(plan, jT, whatsappGroupId) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      plan,
      whatsappGroupId,
    }),
  };

  return fetch(
    `${hostname}/api/v1/properties/payments/request`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        return Promise.reject("403");
      }

      const error = (json && json.message) || response.statusText;
      return Promise.reject(error);
    }
    return json;
  });
}
