import { requirementsConstants } from "../_constants";
import { requirementsServices } from "../_services";
import { authServices } from "../_services";
import { authConstants } from "../_constants";

export const requirementsActions = {
  setRequirementsDetails,
  postRequirements,
  postCommunities,
};

function postRequirements(requirementsDetails) {
  return (dispatch) => {
    dispatch(request());
    requirementsServices.postRequirements(requirementsDetails).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: requirementsConstants.POST_REQUIREMENTS_REQUEST };
  }
  function success(response) {
    return { type: requirementsConstants.POST_REQUIREMENTS_SUCCESS, response };
  }
  function failure(error) {
    return { type: requirementsConstants.POST_REQUIREMENTS_FAILURE, error };
  }
}

function postCommunities(
  requirementsDetails,
  plan,
  jT,
  userDetails,
  whatsappGroupId
) {
  return (dispatch) => {
    dispatch(request());
    requirementsServices.postRequirements(requirementsDetails).then(
      (response) => {
        if (plan === "community_basic") {
          location.href = whatsappGroupId;
          dispatch(success(response));
        } else {
          requirementsServices.payment(plan, jT, whatsappGroupId).then(
            (paymentResponse) => {
              location.href = paymentResponse.data.paymentUrl;
            },
            (error1) => {
              if (error1 == "403") {
                authServices
                  .refreshToken(userDetails.refreshToken)
                  .then((response2) => {
                    dispatch(
                      refreshSuccess(response2.data.jT, response2.data.user)
                    );
                    var newjT = response2.data.jT;
                    requirementsServices
                      .payment(plan, newjT, whatsappGroupId)
                      .then(
                        (paymentResponse1) => {
                          location.href = paymentResponse1.data.paymentUrl;
                        },
                        (error) => {
                          dispatch(failure(error.toString()));
                        }
                      );
                  });
              }
              dispatch(failure(error1));
            }
          );
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: requirementsConstants.POST_REQUIREMENTS_REQUEST };
  }
  function success(response) {
    return { type: requirementsConstants.POST_REQUIREMENTS_SUCCESS, response };
  }
  function failure(error) {
    return { type: requirementsConstants.POST_REQUIREMENTS_FAILURE, error };
  }
  function refreshSuccess(jT, userDetails) {
    return { type: authConstants.REFRESH_SUCCESS, jT, userDetails };
  }
}

function setRequirementsDetails(detailName, detailValue) {
  return (dispatch) => {
    dispatch(success(detailName, detailValue));
  };
  function success(detailName, detailValue) {
    return {
      type: requirementsConstants.SET_REQUIREMENTS_DETAILS,
      detailName,
      detailValue,
    };
  }
}
