export const listingsServices = {
  getListings,
  getMyListings,
  getContactNumber
};

const hostname = "findmyroom.in";

function getListings(
  gender,
  roomType,
  apartmentType,
  amenities,
  furnish,
  minPrice,
  maxPrice,
  sw,
  ne,
  p,
  c
) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": "*"
    },
    body: null
  };

  let url = `https://${hostname}/api/v1/properties/listings?`;

  if (gender) {
    url = url + `gender=${gender}`;
    url = url + "&";
  }

  if (roomType && roomType.length) {
    url = url + "postingFor=";
    for (var i = 0; i < roomType.length; i++) {
      url = url + roomType[i];
      if (i !== roomType.length) {
        url = url + ",";
      }
    }
    url = url + "&";
  }

  if (apartmentType && apartmentType.length) {
    url = url + "bhkType=";
    for (var i = 0; i < apartmentType.length; i++) {
      url = url + apartmentType[i];
      if (i !== apartmentType.length) {
        url = url + ",";
      }
    }
    url = url + "&";
  }

  if (amenities && amenities.length) {
    url = url + "amenities=";
    for (var i = 0; i < amenities.length; i++) {
      url = url + amenities[i];
      if (i !== amenities.length) {
        url = url + ",";
      }
    }
    url = url + "&";
  }

  if (furnish && furnish.length) {
    url = url + "furnishing=";
    for (var i = 0; i < furnish.length; i++) {
      url = url + furnish[i];
      if (i !== furnish.length) {
        url = url + ",";
      }
    }
    url = url + "&";
  }

  if (minPrice) {
    url = url + `minPrice=${minPrice}`;
    url = url + "&";
  }

  if (maxPrice) {
    url = url + `maxPrice=${maxPrice}`;
    url = url + "&";
  }

  if (sw) {
    url = url + `sw=${sw}`;
    url = url + "&";
  }

  if (ne) {
    url = url + `ne=${ne}`;
    url = url + "&";
  }

  if (p) {
    url = url + `p=${p}`;
    url = url + "&";
  }
  if (c) {
    url = url + `c=${c}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}
/* 
function getAccessToken(refreshToken) {
    const requestOptions = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({
            "refreshToken": refreshToken
        })
    };

    return fetch(`https://${hostname}/auth/getAccessToken`, requestOptions)
        .then(handleResponse)
}
 */

function getMyListings(jT) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return fetch(`https://${hostname}/api/v1/properties/`, requestOptions).then(
    handleResponse
  );
}

function getContactNumber(propertyId, jT) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return fetch(
    `https://${hostname}/api/v1/properties/${propertyId}/contact`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then(json => {
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        console.log("403", "sddsaasad");
        return Promise.reject("403");
      }

      const error = (json && json.message) || response.statusText;
      return Promise.reject(error);
    }
    return json;
  });
}
